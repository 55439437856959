
















import {Component, Vue} from "vue-property-decorator";
import Tab from "@/components/Tab.vue";

@Component({
  name: "Tabs"
})
export default class Tabs extends Vue {

  public tabs: any = [];

  constructor() {
    super();
    this.tabs = this.$children;
  }

  selectTab(selectedTab) {
    this.tabs.forEach((tab: Tab) => {
      tab.isActive = (tab.name == selectedTab.name);
    });
  }
}
